"use client"

import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"
import colors from "theme/colors"
import {
  Container,
  NewText,
  transitionDelayTime,
  TransitionUp,
  useInViewConfig,
} from "ui"
import ImageWithAlt from "ui/ImageWithAlt"
import { extractImage, extractProperty } from "utils"
const StyledContainer = styled.div`
  padding-bottom: 56px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 112px 0px;
    padding-top: 80px;
  }
`
const StyledIconsContainer = styled.div<{ gridItems: number }>`
  display: flex;
  flex-direction: column;
  gap: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: grid;
    grid-template-columns: repeat(${(props) => props.gridItems}, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 56px;
  }
`

const StyledIcon = styled.div``

const StyledIconImage = styled.div`
  width: 69px;
  height: 69px;
  position: relative;
  margin-bottom: 16px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 24px;
  }
`
const StyledIconTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${(props) => props.theme.breakpoints.up("md")} {
    gap: 16px;
  }
`
const StyledTitleContainer = styled.div`
  margin-bottom: 40px;
  ${(props) => props.theme.breakpoints.up("md")} {
    margin-bottom: 80px;
  }
`
type Props = {
  data: any
}

function BonitaIcons({ data }: Props) {
  const title = extractProperty(data, "blockTitle")
  const iconsItems = extractProperty(data, "iconsItems")
  const { ref, inView } = useInView(useInViewConfig)
  const { ref: mobileRef, inView: mobileInView } = useInView(useInViewConfig)
  const transitionDelay = transitionDelayTime * 0.5

  return (
    <Container>
      <StyledContainer>
        {title && (
          <TransitionUp $inView={inView} $delay={transitionDelay}>
            <StyledTitleContainer ref={ref}>
              <NewText
                component={"h2"}
                desktopFontSize={40}
                desktopLineHeight={"44px"}
                desktopFontWeight={400}
                mobileFontSize={32}
                mobileLineHeight={"46px"}
                mobileFontWeight={400}
                color={colors.black}
              >
                {title}
              </NewText>
            </StyledTitleContainer>
          </TransitionUp>
        )}
        <StyledIconsContainer
          ref={mobileRef}
          gridItems={iconsItems?.length % 3 === 0 ? 3 : 4}
        >
          {iconsItems.map((item: any, index: number) => {
            const iconTitle = extractProperty(item, "title")
            const iconDescription = extractProperty(item, "description")
            const image = extractImage(item, "icon")
            return (
              <TransitionUp
                key={index.toString()}
                $inView={mobileInView}
                $delay={transitionDelayTime * 0.5 * index}
              >
                <StyledIcon>
                  <StyledIconImage>
                    <ImageWithAlt image={image} fill />
                  </StyledIconImage>
                  <StyledIconTextContainer>
                    <NewText
                      component={"h3"}
                      desktopFontSize={18}
                      desktopLineHeight={"26px"}
                      desktopFontWeight={600}
                      color={colors.black}
                    >
                      {iconTitle}
                    </NewText>
                    <NewText
                      component={"p"}
                      desktopFontSize={16}
                      desktopLineHeight={"23px"}
                      desktopFontWeight={400}
                      color={colors.black}
                    >
                      {iconDescription}
                    </NewText>
                  </StyledIconTextContainer>
                </StyledIcon>
              </TransitionUp>
            )
          })}
        </StyledIconsContainer>
      </StyledContainer>
    </Container>
  )
}

export default BonitaIcons
